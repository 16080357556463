import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import AuthProvider from "../contexts/AuthContext"
import PrivateRoute from './PrivateRoute'
import '../scss/style.scss'

import LandingDonar from '../components/LandingDonar'
import LandingGratis from '../components/LandingGratis'
import Gracias from '../components/Gracias'
import RegistroVendedor from '../components/RegistroVendedor'
import AdminDonador from '../components/AdminDonador'
import PantallaNombres from '../components/PantallaNombres'
import PantallaNombresYKT from '../components/PantallaNombresYKT'
import PantallaNombresSinAnonimo from '../components/PantallaNombresSinAnonimo'
import PantallaNombresTodos from '../components/PantallaNombresTodos'
import ForgotPassword from '../components/Users/ForgotPassword'

import Login from '../components/Users/Login'
import Dashboard from '../components/Dashboard'
import Vendedores from '../components/Vendedores'
import Donadores from '../components/Donadores'
import Suscripciones from '../components/Suscripciones'
import SuscripcionesGratis from '../components/SuscripcionesGratis'
import Unicos from '../components/Unicos'
import Usuarios from '../components/Users/Usuarios'
import Historial from '../components/Historial'
import Gifts from '../components/Gifts'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

function Routes() {
  {
    return (
      <AuthProvider>
        <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/" component={LandingDonar} />
              <Route exact path="/gift" component={LandingGratis} />
              <Route exact path="/gracias" component={Gracias} />
              <Route exact path="/registro" component={RegistroVendedor} />
              <Route exact path="/cuenta" component={AdminDonador} />
              <Route exact path="/pantallanombres" component={PantallaNombres} />
              <Route exact path="/pantallaNombresYkt" component={PantallaNombresYKT} />
              <Route exact path="/pantallanombresanonimo" component={PantallaNombresSinAnonimo} />
              <Route exact path="/pantallanombrestodos" component={PantallaNombresTodos} />
              <Route exact path="/forgot" component={ForgotPassword} />

              <Route exact path="/login" component={Login} />
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/vendedores" component={Vendedores} />
              <PrivateRoute exact path="/donadores" component={Donadores} />
              <PrivateRoute exact path="/suscripciones" component={Suscripciones} />
              <PrivateRoute exact path="/suscripcionesgratis" component={SuscripcionesGratis} />
              <PrivateRoute exact path="/giftslista" component={Gifts} />
              <PrivateRoute exact path="/unicos" component={Unicos} />
              <PrivateRoute exact path="/usuarios" component={Usuarios} />
              <PrivateRoute exact path="/historial" component={Historial} />
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </AuthProvider>
    )
  }
}

export default Routes